import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "Home",
  setup: function setup() {
    var bannerList = ref([{
      url: require('../../assets/banner.jpg'),
      type: 1
    }, {
      url: require('../../assets/banner2.jpg'),
      type: 2
    }, {
      url: require('../../assets/banner.jpg'),
      type: 3
    }, {
      url: require('../../assets/banner2.jpg'),
      type: 4
    }]);
    var router = useRouter();

    var handleIntroduction = function handleIntroduction() {
      router.push('/introduction');
    };

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
    });
    var topbanner = ref(null);

    var next = function next() {
      topbanner.value.next();
    };

    var pre = function pre() {
      topbanner.value.prev();
    };

    var banner_active = ref(0);

    var changeItem = function changeItem(e) {
      banner_active.value = e;
    };

    return {
      bannerList: bannerList,
      handleIntroduction: handleIntroduction,
      handlebackTop: handlebackTop,
      topbanner: topbanner,
      next: next,
      pre: pre,
      banner_active: banner_active,
      changeItem: changeItem
    };
  } // mounted(){
  //   window.addEventListener("scroll", this.handleScrollx, true);
  // }

});